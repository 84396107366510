import React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Mail from '@mui/icons-material/Mail';
import { ActionsDrawer } from '@fingo/lib/components/drawer';
import DrawerHeader from '@fingo/lib/components/drawer/DrawerHeader';
import { useBooleanState } from '@fingo/lib/hooks';
import ConfigureMailDrawer from './ConfigureMailDrawer';

const ConfigureMail = () => {
  const [drawerOpen, toggleDrawer] = useBooleanState(false);

  return (
    <>
      <Tooltip title="Crear y editar mails / Carga Masiva de asignaciones">
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={toggleDrawer}
          startIcon={<Mail />}
        >
          Configurar Mail / Cargar asignaciones
        </Button>
      </Tooltip>
      <ActionsDrawer
        title="Configurar Mail"
        width={800}
        open={drawerOpen}
        setOpen={toggleDrawer}
        headerComponent={DrawerHeader}
        contentComponent={ConfigureMailDrawer}
      />
    </>
  );
};

export default ConfigureMail;
