import { useMutation } from '@apollo/client';
import { LoadingIconButton } from '@fingo/lib/components/buttons';
import React from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import PropTypes from 'prop-types';
import { LARA } from '../../../graphql/companies';

const Lara = ({ masterEntityId }) => {
  const [
    fetchLara, { loading: loadingLara },
  ] = useMutation(LARA, { variables: { masterEntityId } });
  return (
    <LoadingIconButton
      onClick={fetchLara}
      sx={{ p: 0 }}
      size="small"
      loading={loadingLara}
      color="success"
      id="lara"
    >
      <GetAppIcon />
    </LoadingIconButton>
  );
};

Lara.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
};

export default Lara;
