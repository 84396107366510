import ExecutiveFilter from '@fingo/lib/components/filters/ExecutiveFilter';
import HuntingFarmingFilter from '@fingo/lib/components/filters/HuntingFarmingFilter';
import { PropTypes } from 'prop-types';
import React from 'react';

const ContractMasterEntityActions = ({ setExecutiveAssigned,
  setFarmingHunting }) => (
    <>
      <ExecutiveFilter setExecutiveCallback={setExecutiveAssigned} />
      <HuntingFarmingFilter setHuntingFarmingCallback={setFarmingHunting} />
    </>
);

ContractMasterEntityActions.propTypes = {
  setExecutiveAssigned: PropTypes.func.isRequired,
  setFarmingHunting: PropTypes.func.isRequired,
};

export default ContractMasterEntityActions;
