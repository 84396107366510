import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { PENDING_CONTRACTS } from '@fingo/lib/graphql';
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CONTRACT_MANAGER_COLUMNS } from '../../constants/contracts-manager';
import ContractActions from '../contracts-manager/actions/ContractActions';

const ContractDetail = ({ masterEntity }) => {
  const [selectedEvaluationRequestsIds, setSelectedEvaluationRequestsIds] = useState([]);

  return (
    <FingoMainView
      id="customers-manager"
      query={PENDING_CONTRACTS}
      queryCustomVariables={{
        inPendingFrameworkContract: true,
        companyId: masterEntity?.graphqlId,
      }}
      slots={{
        header: null,
        table: FingoDataGrid,
        actions: ContractActions,
      }}
      slotProps={{
        actions: {
          selectedEvaluationRequestsIds,
        },
        table: {
          columns: CONTRACT_MANAGER_COLUMNS,
          noRowsMessage: () => <Typography>No tienes clientes asociados</Typography>,
          onSelectionModelChange: (ids) => { setSelectedEvaluationRequestsIds(ids); },
          checkboxSelection: true,
          includeHeaders: [
            'contract_status',
            'company_formation_type',
            'contract_generation',
            'check_contract_status',
          ],
          rowsPerPageOptions: [15, 25, 50, 100],
        },
      }}
    />
  );
};

export default ContractDetail;

ContractDetail.propTypes = {
  masterEntity: PropTypes.shape(
    {
      graphqlId: PropTypes.string,
    },
  ).isRequired,
};
