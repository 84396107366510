import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ServerSideDataGrid from '@fingo/lib/components/dataGrids/ServerDataGrid';
import { formatYearMonthDay } from '@fingo/lib/helpers';
import { useReactiveVar } from '@apollo/client';
import { selectedInvoiceRecommendationIdsVar } from '../../../graphql/reactive-variables';
import { INVOICE_RECOMMENDATION_COLUMNS } from '../../../constants/company-recommendations';
import { INVOICE_RECOMMENDATIONS } from '../../../graphql/company-recommendations/queries';

const CompanyInvoiceRecommendations = ({ row }) => {
  const selectedInvoiceRecommendationIds = useReactiveVar(
    selectedInvoiceRecommendationIdsVar,
  );

  return (
    <ServerSideDataGrid
      columns={INVOICE_RECOMMENDATION_COLUMNS}
      apolloQuery={{
        query: INVOICE_RECOMMENDATIONS,
        variables: {
          companyId: row.id,
          status: 'ACTIVE',
          visible: true,
          dateIssued: formatYearMonthDay(moment().subtract(14, 'days')),
        },
      }}
      disableSelectionOnClick
      checkboxSelection
      keepNonExistentRowsSelected
      selectionModel={selectedInvoiceRecommendationIds}
      onSelectionModelChange={(ids) => {
        selectedInvoiceRecommendationIdsVar(ids);
      }}
      density="compact"
    />
  );
};

CompanyInvoiceRecommendations.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default CompanyInvoiceRecommendations;
