import React from 'react';
import { useMutation } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { CHECK_CONTRACT_STATUS, PENDING_CONTRACTS } from '@fingo/lib/graphql';
import { useSnackBars } from '@fingo/lib/hooks';

const CheckContractStatusButton = ({ selectedEvaluationRequestsIds }) => {
  const isDisabled = selectedEvaluationRequestsIds?.length !== 1;
  const { addAlert } = useSnackBars();
  const [checkContractStatus, { loading }] = useMutation(
    CHECK_CONTRACT_STATUS,
    { refetchQueries: [PENDING_CONTRACTS] },
  );

  const handleClick = () => {
    checkContractStatus({
      variables: {
        contractRequestId: selectedEvaluationRequestsIds[0],
      },
      onCompleted: () => {
        addAlert({
          id: 'check-contract-status',
          message: 'Estado del contrato actualizado correctamente',
        });
      },
    });
  };

  return (
    <Tooltip title="Actualizar estado del contrato, solo podemos actualizar un contrato a la vez">
      <LoadingButton
        variant="contained"
        onClick={handleClick}
        disabled={isDisabled}
        loading={loading}
      >
        Actualizar estado
      </LoadingButton>
    </Tooltip>
  );
};

CheckContractStatusButton.propTypes = {
  selectedEvaluationRequestsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CheckContractStatusButton;
