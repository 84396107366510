import React, { useState } from 'react';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { PaperHeader } from '@fingo/lib/components/headers';
import Typography from '@mui/material/Typography';
import { CONTACT_TYPES } from '@fingo/lib/constants';
import {
  COLUMNS,
  HEADERS,
  VIEW_TITLE,
  TITLE_INFORMATION_TOOLTIP,
  ROWS_PER_PAGE_OPTIONS,
  INITIAL_PAGE_SIZE,
} from '../../constants/contactability-company';
import { CONTACTABILITY_MASTER_ENTITIES } from '../../graphql/contactability-manager/queries';
import ContactabilityActions from './actions/ContactabilityActions';
import CompanyControlGroups from './CompanyControlGroups';

const Contactability = () => {
  const [mailFilter, setMailFilter] = useState(null);

  return (
    <FingoMainView
      id="contactability-company"
      query={CONTACTABILITY_MASTER_ENTITIES}
      queryCustomVariables={{
        contactType: CONTACT_TYPES.PREOFFER_COMMERCIAL_KEY,
        company_ControlGroupDetail_Isnull: false,
        company_ControlGroupDetail_MailParameter_Mail_Id: mailFilter?.graphqlId,
        showHidden: false,
        distinct: true,
        validSiiCredentials: true,
      }}
      slots={{
        header: PaperHeader,
        table: FingoTable,
        informationTooltipText: TITLE_INFORMATION_TOOLTIP,
        actions: ContactabilityActions,
      }}
      slotProps={{
        header: {
          viewTitle: VIEW_TITLE,
          finder: {
            searchPlaceHolder: 'Buscar empresa',
          },
        },
        table: {
          columns: COLUMNS,
          includeHeaders: HEADERS,
          rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
          initialPageSize: INITIAL_PAGE_SIZE,
          isRowSelectable: () => true,
          disableSelectionOnClick: true,
          collapsible: true,
          isRowCollapsible: () => true,
          collapseComponent: ({ row }) => (
            <CompanyControlGroups company={row.company} masterEntityName={row.name} />
          ),
          noRowsMessage: () => <Typography>Sin empresas</Typography>,
        },
        actions: {
          mailFilter,
          setMailFilter,
        },
      }}
    />
  );
};

export default Contactability;
