import React from 'react';
import { useMutation } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { SOLVE_CONTRACT_REQUEST, PENDING_CONTRACTS } from '@fingo/lib/graphql';
import { useSnackBars } from '@fingo/lib/hooks';

const SolveContractRequestButton = ({ selectedEvaluationRequestsIds }) => {
  const { addAlert } = useSnackBars();
  const [checkContractStatus, { loading }] = useMutation(
    SOLVE_CONTRACT_REQUEST,
    { refetchQueries: [PENDING_CONTRACTS] },
  );

  const handleClick = () => {
    checkContractStatus({
      variables: {
        contractRequestsIds: selectedEvaluationRequestsIds,
      },
      onCompleted: () => {
        addAlert({
          id: 'solve-contract-request',
          message: 'Solicitud de contrato resuelta correctamente',
        });
      },
    });
  };

  return (
    <Tooltip title="Actualizar estado del contrato, solo podemos actualizar un contrato a la vez">
      <LoadingButton
        variant="contained"
        onClick={handleClick}
        disabled={!selectedEvaluationRequestsIds.length}
        loading={loading}
      >
        Resolver solicitud
      </LoadingButton>
    </Tooltip>
  );
};

SolveContractRequestButton.propTypes = {
  selectedEvaluationRequestsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SolveContractRequestButton;
