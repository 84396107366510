import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ContractType = ({ value }) => {
  const contractType = {
    RES: 'Empresa en un día',
    REGULAR: 'Regular' };
  return (
    <Typography>
      {contractType[value]}
    </Typography>
  );
};

ContractType.propTypes = {
  value: PropTypes.string.isRequired,
};

export default ContractType;
