import ExecutiveFilter from '@fingo/lib/components/filters/ExecutiveFilter';
import HuntingFarmingFilter from '@fingo/lib/components/filters/HuntingFarmingFilter';
import MasterEntitySourceFilter from '@fingo/lib/components/filters/MasterEntitySourceFilter';
import { PropTypes } from 'prop-types';
import React from 'react';
import BulkAssignExecutive from './BulkAssignExecutive';
import UploadContactabilityRequest from './UploadContactabilityRequest';

const CustomersActions = ({ setExecutiveAssigned, setFarmingHunting, setMasterEntitySource }) => (
  <>
    <MasterEntitySourceFilter setMasterEntitySourceCallback={setMasterEntitySource} />
    <ExecutiveFilter setExecutiveCallback={setExecutiveAssigned} />
    <HuntingFarmingFilter setHuntingFarmingCallback={setFarmingHunting} />
    <BulkAssignExecutive />
    <UploadContactabilityRequest />
  </>
);

CustomersActions.propTypes = {
  setExecutiveAssigned: PropTypes.func.isRequired,
  setFarmingHunting: PropTypes.func.isRequired,
  setMasterEntitySource: PropTypes.func.isRequired,
};

export default CustomersActions;
