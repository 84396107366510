import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ExecutiveFilter from '@fingo/lib/components/filters/ExecutiveFilter';
import { useGetUser } from '@fingo/lib/hooks';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import Stack from '@mui/material/Stack';
import ConfirmInvoiceRecommendationsAction from './ConfirmInvoiceRecommendationsAction';
import DeclineInvoiceRecommendationsAction from './DeclineInvoiceRecommendationsAction';
import CompanyRecommendationsActionsMenu from './CompanyRecommendationsActionsMenu';

const CompanyRecommendationsActions = ({ onExecutiveChange }) => {
  const user = useGetUser();
  const canDisplayInvoiceRecommendations = useMemo(
    () => userHasRoutePermission(user, 'invoices.display_invoicerecommendation'),
    [user],
  );

  return (
    <Stack direction="row" spacing={1}>
      {canDisplayInvoiceRecommendations && (
        <ExecutiveFilter
          setExecutiveCallback={(executive) => onExecutiveChange(executive?.graphqlId)}
        />
      )}
      <ConfirmInvoiceRecommendationsAction />
      <DeclineInvoiceRecommendationsAction />
      {canDisplayInvoiceRecommendations && (
        <CompanyRecommendationsActionsMenu />
      )}
    </Stack>
  );
};

CompanyRecommendationsActions.propTypes = {
  onExecutiveChange: PropTypes.func.isRequired,
};

export default CompanyRecommendationsActions;
