import React, { useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DisplayInvoiceRecommendationsDialog from '../../dialogs/DisplayInvoiceRecommendationsDialog';

const ITEM_HEIGHT = 48;

const CompanyRecommendationsActionsMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [
    openDisplayInvoiceRecommendationsDialog,
    setOpenDisplayInvoiceRecommendationsDialog,
  ] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuOptions = useMemo(
    () => [
      {
        id: 'display-invoice-recommendations',
        label: 'Visibilizar recomendaciones',
        onClick: () => setOpenDisplayInvoiceRecommendationsDialog(true),
      },
    ],
    [],
  );

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          },
        }}
      >
        {menuOptions.map(({ id, label, onClick }) => (
          <MenuItem key={id} onClick={onClick}>
            {label}
          </MenuItem>
        ))}
      </Menu>
      {openDisplayInvoiceRecommendationsDialog && (
        <DisplayInvoiceRecommendationsDialog
          open={openDisplayInvoiceRecommendationsDialog}
          onClose={() => {
            setOpenDisplayInvoiceRecommendationsDialog(false);
            handleClose();
          }}
        />
      )}
    </>
  );
};

export default CompanyRecommendationsActionsMenu;
