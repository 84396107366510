import { useMutation } from '@apollo/client';
import { UploadDropZoneDialog } from '@fingo/lib/components/dialogs';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import { useBooleanState, useGetUser, useSnackBars } from '@fingo/lib/hooks';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { BULK_ASSIGN_EXECUTIVE } from '../../../graphql/executives';

const BulkAssignExecutive = () => {
  const user = useGetUser();
  const [open, toggleOpen] = useBooleanState();
  const { addAlert } = useSnackBars();
  const [files, setFiles] = useState([{ name: '' }]);
  const [bulkAssignExecutive, { loading }] = useMutation(
    BULK_ASSIGN_EXECUTIVE,
    {
      variables: {
        assignations: files[0],
      },
      onCompleted: () => {
        setFiles([{ name: '' }]);
        toggleOpen();
      },
      onError: (error) => {
        addAlert({
          id: 'executive-bulk-error',
          message: `Ha ocurrido un error con la asignación masiva de ejecutivos. ${error}`,
        });
      },
    },
  );
  if (!userHasRoutePermission(user, 'users.change_userexecutive')) return null;
  return (
    <>
      <Button
        onClick={toggleOpen}
        variant="contained"
        color="primary"
        size="small"
      >
        Carga masiva de ejecutivos
      </Button>
      <UploadDropZoneDialog
        open={open}
        setOpen={toggleOpen}
        files={files}
        setFiles={setFiles}
        message="Suba un excel con las columnas company_id y user_id"
        downloadTemplate={() => {}}
        onCompleted={bulkAssignExecutive}
        loading={loading}
      />
    </>
  );
};

export default BulkAssignExecutive;
