/* eslint-disable react/prop-types */
import React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useBooleanState } from '@fingo/lib/hooks';
import ActionsDrawer from '@fingo/lib/components/drawer/ActionsDrawer';
import DrawerHeader from '@fingo/lib/components/drawer/DrawerHeader';
import ConfigureMailDrawer from './actions/ConfigureMailDrawer';

const MailTemplateButton = ({ mailParam, companyId, masterEntityName }) => {
  const [drawerOpen, toggleDrawer] = useBooleanState(false);

  return (
    <>
      <Tooltip title="Cambiar plantilla">
        <Button
          variant="contained"
          size="small"
          sx={{ borderRadius: '50px', fontWeight: 'bold', fontSize: '0.7rem', padding: '2px 4px' }}
          color={mailParam.mailTemplate?.version !== 'BLACKLIST' ? 'success' : 'error'}
          onClick={toggleDrawer}
        >
          {mailParam.mailTemplate?.version}
        </Button>
      </Tooltip>
      <ActionsDrawer
        title="Asignar plantilla a empresa"
        subtitle={masterEntityName}
        width={800}
        open={drawerOpen}
        setOpen={toggleDrawer}
        headerComponent={DrawerHeader}
        contentComponent={() => (
          <ConfigureMailDrawer
            mailId={mailParam.mail.id}
            mailParameterId={mailParam.id}
            companyId={companyId}
            specificMode
            drawerOpen={drawerOpen}
          />
        )}
      />
    </>
  );
};
export default MailTemplateButton;
