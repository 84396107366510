import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useMutation, useReactiveVar } from '@apollo/client';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import CompanyFilter from '@fingo/lib/components/filters/CompanyFilter';
import { useSnackBars } from '@fingo/lib/hooks';
import { RECOMMENDED_MASTER_ENTITIES } from '@fingo/lib/graphql';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import { selectedInvoiceRecommendationIdsVar } from '../../graphql/reactive-variables';
import { DECLINE_INVOICE_RECOMMENDATIONS } from '../../graphql/company-recommendations/mutations';
import {
  DECLINE_INVOICE_RECOMMENDATIONS_CLIENT_INTEREST_OPTIONS,
  DECLINE_INVOICE_RECOMMENDATIONS_FAILURE_REASONS,
  DECLINE_INVOICE_RECOMMENDATIONS_INITIAL_VALUES,
  DECLINE_INVOICE_RECOMMENDATIONS_VALIDATION_SCHEMA,
} from '../../constants/company-recommendations';

const DeclineInvoiceRecommendationsDialog = ({ open, handleClose }) => {
  const { addAlert } = useSnackBars();
  const selectedInvoiceRecommendationIds = useReactiveVar(
    selectedInvoiceRecommendationIdsVar,
  );

  const [declineInvoiceRecommendations] = useMutation(
    DECLINE_INVOICE_RECOMMENDATIONS,
    {
      variables: { invoiceRecommendationIds: selectedInvoiceRecommendationIds },
      refetchQueries: [RECOMMENDED_MASTER_ENTITIES],
      update: (cache) => {
        cache.modify({
          fields: {
            invoiceRecommendations(
              existingData = { edges: [], totalCount: 0 },
            ) {
              const { edges, totalCount } = existingData;
              const updatedEdges = edges.filter((edge) => {
                const nodeRef = cache.identify(edge.node);
                const nodeId = nodeRef.split(':')[1];
                return !selectedInvoiceRecommendationIds.includes(nodeId);
              });
              const difference = edges.length - updatedEdges.length;
              const newTotalCount = totalCount - difference;
              return {
                ...existingData,
                totalCount: newTotalCount,
                edges: updatedEdges,
              };
            },
          },
        });
      },
      onCompleted: () => {
        addAlert({
          id: 'decline-invoice-recommendations-success',
          message: 'Facturas gestionadas con éxito!',
          severity: 'success',
          color: 'success',
        });
      },
      onError: ({ message }) => {
        addAlert({
          id: 'decline-invoice-recommendations-error',
          message,
          severity: 'error',
          color: 'error',
        });
      },
    },
  );

  return (
    <FingoDialog
      open={open}
      handleClose={handleClose}
      title="Descartar recomendaciones"
      maxWidth="md"
      fullWidth
    >
      <Formik
        initialValues={DECLINE_INVOICE_RECOMMENDATIONS_INITIAL_VALUES}
        validationSchema={DECLINE_INVOICE_RECOMMENDATIONS_VALIDATION_SCHEMA}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const { details, company, rate, failureReason, clientInterest } = values;
          let parsedDetails = details;
          if (failureReason === 'CUSTOMER_WITH_COMPETITORS') {
            const companyDetails = `${company.name} ${company.displayNationalIdentifier}`;
            parsedDetails = `${details}\nInstitución: ${companyDetails}, Tasa: ${rate}`;
          }

          declineInvoiceRecommendations({
            variables: {
              failureReason,
              clientInterest,
              details: parsedDetails,
            },
            onCompleted: () => {
              setSubmitting(false);
              selectedInvoiceRecommendationIdsVar([]);
              resetForm();
              handleClose();
            },
            onError: () => {
              setSubmitting(false);
            },
          });
        }}
      >
        {({
          values,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleBlur,
          errors,
          touched,
          isValid,
        }) => (
          <Form
            style={{
              padding: 16,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5" alignSelf="flex-start" mb={1}>
              ¿Por qué motivo no evaluará el cliente?
            </Typography>
            <Autocomplete
              options={DECLINE_INVOICE_RECOMMENDATIONS_FAILURE_REASONS}
              onChange={(event, { value }) => {
                setFieldValue('failureReason', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  error={touched.failureReason && Boolean(errors.failureReason)}
                  helperText={
                    touched.failureReason && errors.failureReason
                      ? errors.failureReason
                      : ' '
                  }
                  FormHelperTextProps={{ sx: { minHeight: 24 } }}
                  sx={{
                    '& .MuiFilledInput-root': {
                      height: 60,
                      pt: 0,
                      fontSize: 14,
                      border: '1px solid #ccc',
                      backgroundColor: '#f9f9f9',
                      '&:hover': { backgroundColor: '#F9F9F9' },
                    },
                  }}
                />
              )}
              sx={{
                borderRadius: 2,
                width: 1,
                '& .MuiAutocomplete-popupIndicator': {
                  color: 'primary.main',
                },
              }}
            />
            {values.failureReason === 'CUSTOMER_WITH_COMPETITORS' && (
              <Stack
                direction="row"
                width={1}
                spacing={4}
                justifyContent="space-around"
                alignItems="center"
                mb={1}
              >
                <CompanyFilter
                  inputLabel="Selecciona factoring"
                  selectedCompany={values.company}
                  setSelectedCompany={(value) => {
                    setFieldValue('company', value);
                  }}
                  fullWidth
                />
                <TextField
                  variant="filled"
                  label="Tasa competencia"
                  value={values.rate}
                  onChange={(e) => {
                    setFieldValue('rate', e.target.value);
                  }}
                />
              </Stack>
            )}

            <Typography variant="h5" alignSelf="flex-start" mb={1}>
              ¿Qué interés muestra el cliente por Fingo?
            </Typography>
            <Autocomplete
              options={DECLINE_INVOICE_RECOMMENDATIONS_CLIENT_INTEREST_OPTIONS}
              onChange={(event, { value }) => {
                setFieldValue('clientInterest', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  error={
                    touched.clientInterest && Boolean(errors.clientInterest)
                  }
                  helperText={
                    touched.clientInterest && errors.clientInterest
                      ? errors.clientInterest
                      : ' '
                  }
                  FormHelperTextProps={{ sx: { minHeight: 24 } }}
                  sx={{
                    '& .MuiFilledInput-root': {
                      height: 60,
                      pt: 0,
                      fontSize: 14,
                      border: '1px solid #ccc',
                      backgroundColor: '#f9f9f9',
                      '&:hover': { backgroundColor: '#F9F9F9' },
                    },
                  }}
                />
              )}
              sx={{
                borderRadius: 2,
                width: 1,
                '& .MuiAutocomplete-popupIndicator': {
                  color: 'primary.main',
                },
              }}
            />
            <Typography variant="h5" alignSelf="flex-start" mb={1}>
              Comentarios (Opcional)
            </Typography>
            <TextField
              name="details"
              variant="filled"
              rows={4}
              multiline
              fullWidth
              value={values.details}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.details && Boolean(errors.details)}
              helperText={
                touched.details && errors.details ? errors.details : ' '
              }
              FormHelperTextProps={{ sx: { minHeight: 24 } }}
              InputProps={{
                sx: {
                  height: 'auto',
                  fontSize: 14,
                  padding: 2,
                  border: '1px solid #ccc',
                  backgroundColor: '#f9f9f9',
                  '&:hover': { backgroundColor: '#F9F9F9' },
                },
              }}
            />
            <LoadingButton
              id="decline-invoice-recommendations-button"
              variant="contained"
              color="primary"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting || !isValid}
              sx={{
                width: 200,
                fontSize: 16,
                height: 40,
              }}
            >
              Confirmar
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </FingoDialog>
  );
};

DeclineInvoiceRecommendationsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DeclineInvoiceRecommendationsDialog;
