import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { useMasterEntityPreColumns } from '@fingo/lib/constants';
import { CONTRACT_MASTER_ENTITIES } from '@fingo/lib/graphql';
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import ContractDetail from './ContractDetail';
import ContractMasterEntityActions from '../contracts-manager/actions/ContractMasterEntitysActions';
import { CONTRACT_MASTER_ENTITY_COLUMNS } from '../../constants/contracts-manager';

const Contracts = () => {
  const country = useGetCountryFromUrl();
  const [executiveAssigned, setExecutiveAssigned] = useState('');
  const [farmingHunting, setFarmingHunting] = useState('');

  return (
    <FingoMainView
      id="customers-manager"
      query={CONTRACT_MASTER_ENTITIES}
      queryCustomVariables={{
        company_Isnull: false,
        countryId: country?.id,
        inPendingFrameworkContract: true,
        companyExecutiveAssignedId: executiveAssigned ? executiveAssigned.graphqlId : undefined,
        farmingByCommercialType: farmingHunting || undefined,
      }}
      slots={{
        header: PaperHeader,
        table: FingoTable,
        actions: ContractMasterEntityActions,
      }}
      slotProps={{
        header: {
          viewTitle: 'Solicitudes de contrato',
          finder: {
            searchPlaceHolder: 'Buscar cliente',
          },
        },
        actions: {
          setExecutiveAssigned,
          setFarmingHunting,
        },
        table: {
          columns: useMasterEntityPreColumns(CONTRACT_MASTER_ENTITY_COLUMNS, true),
          noRowsMessage: () => <Typography>No tienes clientes asociados</Typography>,
          collapseComponent: ({ row }) => (
            <ContractDetail masterEntity={row} />
          ),
          collapsible: true,
          isRowCollapsible: () => true,
          includeHeaders: [
            'nameWithProfile',
            'contract_documents',
            'company_ExecutiveAssigned__firstName',
          ],
          rowsPerPageOptions: [15, 25, 50, 100],
        },
      }}
    />
  );
};

export default Contracts;
