import React from 'react';
import Stack from '@mui/material/Stack';
import { MailFilter } from '@fingo/lib/components/filters';
import PropTypes from 'prop-types';
import { MailType } from '@fingo/lib/propTypes';
import ConfigureMail from './ConfigureMail';

const ContactabilityActions = ({ setMailFilter, mailFilter }) => (
  <Stack direction="row" spacing={2}>
    <MailFilter
      size="small"
      sx={{ width: '300px' }}
      selectedMail={mailFilter}
      setSelectedMail={setMailFilter}
      filterMode
    />
    <ConfigureMail />
  </Stack>
);

ContactabilityActions.propTypes = {
  setMailFilter: PropTypes.func.isRequired,
  mailFilter: MailType,
};

ContactabilityActions.defaultProps = {
  mailFilter: null,
};

export default ContactabilityActions;
