import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import Chip from '@mui/material/Chip';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import { selectedInvoiceRecommendationIdsVar } from '../../../graphql/reactive-variables';
import ConfirmInvoiceRecommendationsDialog from '../../dialogs/ConfirmInvoiceRecommendationsDialog';

const ConfirmInvoiceRecommendationsAction = () => {
  const [
    openConfirmInvoiceRecommendationsDialog,
    setOpenConfirmInvoiceRecommendationsDialog,
  ] = useState(false);
  const selectedInvoiceRecommendationIds = useReactiveVar(
    selectedInvoiceRecommendationIdsVar,
  );

  return (
    <>
      <Chip
        id="confirm-invoice-recommendation-chip"
        icon={<AutoAwesome />}
        label="Gestión efectiva"
        disabled={selectedInvoiceRecommendationIds.length === 0}
        color="success"
        onClick={() => setOpenConfirmInvoiceRecommendationsDialog(true)}
      />
      {openConfirmInvoiceRecommendationsDialog && (
        <ConfirmInvoiceRecommendationsDialog
          open={openConfirmInvoiceRecommendationsDialog}
          handleClose={() => setOpenConfirmInvoiceRecommendationsDialog(false)}
        />
      )}
    </>
  );
};

export default ConfirmInvoiceRecommendationsAction;
