import React from 'react';
import PropTypes from 'prop-types';
import CheckContractStatusButton from './CheckContractStatusButton';
import SolveContractRequestButton from './SolveContractRequestButton';

const ContractActions = ({ selectedEvaluationRequestsIds }) => (
  <>
    <CheckContractStatusButton
      selectedEvaluationRequestsIds={selectedEvaluationRequestsIds}
    />
    <SolveContractRequestButton
      selectedEvaluationRequestsIds={selectedEvaluationRequestsIds}
    />
  </>
);

export default ContractActions;

ContractActions.propTypes = {
  selectedEvaluationRequestsIds: PropTypes.arrayOf(PropTypes.shape).isRequired,
};
