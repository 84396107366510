import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { MailForm, MailParamForm } from '@fingo/lib/components/forms';
import { formatSelectedMail, formatSelectedMailParam } from '@fingo/lib/propTypes';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { SINGLE_MAIL_WITH_PARAMETERS } from '@fingo/lib/graphql/notification-center/queries';
import Mail from './Mail';
import MailParameter from './MailParameter';
import MailTemplate from './MailTemplate';

const ConfigureMailDrawer = ({ mailId, mailParameterId, specificMode, companyId, drawerOpen }) => {
  const [selectedMail, setSelectedMail] = useState();
  const [selectedMailParam, setSelectedMailParam] = useState();
  const [mailVersionsForParam, setMailVersionsForParam] = useState([]);
  const [openCreateMailForm, setOpenCreateMailForm] = useState(false);
  const [openCreateMailParamForm, setOpenCreateMailParamForm] = useState(false);

  const setVersionOptions = (mail, mailParam) => {
    const versions = mail.mailParams
      ?.filter((param) => param.country.id === mailParam?.country.id
        && param.requestingPlatform.id === mailParam?.requestingPlatform.id)
      .map((param) => param.template);
    versions?.sort((a, b) => a.version?.localeCompare(b.version));
    setMailVersionsForParam(versions);
  };

  const setSpecificMode = (data) => {
    const formatedMail = formatSelectedMail(data.mail);
    setSelectedMail(formatedMail);
    const mailParam = data.mail.mailparametersSet.find((param) => param.id === mailParameterId);
    const formatedMailParam = formatSelectedMailParam(mailParam);
    setSelectedMailParam(formatedMailParam);
    setVersionOptions(formatedMail, formatedMailParam);
  };

  const { refetch: getMail } = useQuery(
    SINGLE_MAIL_WITH_PARAMETERS,
    { variables: { mailId },
      onCompleted: (data) => setSpecificMode(data) },
  );

  useEffect(() => {
    if (!specificMode) { setSelectedMailParam(null); }
  }, [selectedMail]);

  useEffect(() => {
    if (specificMode && drawerOpen) {
      getMail({ variables: { mailId } });
    }
  }, [mailId, mailParameterId]);

  useEffect(() => {
    if (selectedMailParam) {
      setVersionOptions(selectedMail, selectedMailParam);
    }
  }, [selectedMailParam]);

  return (
    <Stack spacing={2}>
      <Stack spacing={1} direction="row">
        <Mail
          selectedMail={selectedMail}
          setSelectedMail={setSelectedMail}
          openCreateMailForm={openCreateMailForm}
          setOpenCreateMailForm={setOpenCreateMailForm}
          specificMode={specificMode}
        />
        { selectedMail && (
          <MailParameter
            mailParams={selectedMail?.mailParams}
            selectedMailParam={selectedMailParam}
            setSelectedMailParam={setSelectedMailParam}
            openCreateMailParamForm={openCreateMailParamForm}
            setOpenCreateMailParamForm={setOpenCreateMailParamForm}
            specificMode={specificMode}
          />
        )}
      </Stack>
      <Stack spacing={1}>
        {openCreateMailForm && (
          <MailForm
            selectedMail={selectedMail}
            setSelectedMail={setSelectedMail}
            setOpenCreateMailForm={setOpenCreateMailForm}
          />
        )}
        {openCreateMailParamForm && (
          <MailParamForm
            selectedMail={selectedMail}
            setSelectedMail={setSelectedMail}
            selectedMailParam={selectedMailParam}
            setSelectedMailParam={setSelectedMailParam}
            setOpenCreateMailParamForm={setOpenCreateMailParamForm}
          />
        )}
      </Stack>
      { selectedMailParam && (
        <MailTemplate
          mailParams={selectedMail?.mailParams}
          selectedMailParam={selectedMailParam}
          setSelectedMailParam={setSelectedMailParam}
          mailVersionsForParam={mailVersionsForParam}
          companyId={companyId}
          setSelectedMail={setSelectedMail}
        />
      )}
    </Stack>
  );
};

ConfigureMailDrawer.propTypes = {
  mailId: PropTypes.string,
  mailParameterId: PropTypes.string,
  specificMode: PropTypes.bool,
  companyId: PropTypes.string,
  drawerOpen: PropTypes.bool,
};

ConfigureMailDrawer.defaultProps = {
  mailId: null,
  mailParameterId: null,
  companyId: null,
  specificMode: false,
  drawerOpen: false,
};

export default ConfigureMailDrawer;
