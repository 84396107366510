import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import moment from 'moment';
import { useMutation, useReactiveVar } from '@apollo/client';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useSnackBars } from '@fingo/lib/hooks';
import { RECOMMENDED_MASTER_ENTITIES } from '@fingo/lib/graphql';
import { FingoDatePicker } from '@fingo/lib/components/datePickers';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import { selectedInvoiceRecommendationIdsVar } from '../../graphql/reactive-variables';
import { CONFIRM_INVOICE_RECOMMENDATIONS } from '../../graphql/company-recommendations/mutations';
import {
  CONFIRM_INVOICE_RECOMMENDATIONS_INITIAL_VALUES,
  CONFIRM_INVOICE_RECOMMENDATIONS_VALIDATION_SCHEMA,
} from '../../constants/company-recommendations';

const ConfirmInvoiceRecommendationsDialog = ({ open, handleClose }) => {
  const { addAlert } = useSnackBars();
  const selectedInvoiceRecommendationIds = useReactiveVar(
    selectedInvoiceRecommendationIdsVar,
  );

  const [confirmInvoiceRecommendations] = useMutation(
    CONFIRM_INVOICE_RECOMMENDATIONS,
    {
      variables: { invoiceRecommendationIds: selectedInvoiceRecommendationIds },
      refetchQueries: [RECOMMENDED_MASTER_ENTITIES],
      update: (cache) => {
        cache.modify({
          fields: {
            invoiceRecommendations(
              existingData = { edges: [], totalCount: 0 },
            ) {
              const { edges, totalCount } = existingData;
              const updatedEdges = edges.filter((edge) => {
                const nodeRef = cache.identify(edge.node);
                const nodeId = nodeRef.split(':')[1];
                return !selectedInvoiceRecommendationIds.includes(nodeId);
              });
              const difference = edges.length - updatedEdges.length;
              const newTotalCount = totalCount - difference;
              return {
                ...existingData,
                totalCount: newTotalCount,
                edges: updatedEdges,
              };
            },
          },
        });
      },
      onCompleted: () => {
        addAlert({
          id: 'confirm-invoice-recommendations-success',
          message: 'Facturas gestionadas con éxito!',
          severity: 'success',
          color: 'success',
        });
      },
      onError: ({ message }) => {
        addAlert({
          id: 'confirm-invoice-recommendations-error',
          message,
          severity: 'error',
          color: 'error',
        });
      },
    },
  );

  return (
    <FingoDialog
      open={open}
      handleClose={handleClose}
      title="Marcar recomendaciones como efectivas"
      maxWidth="sm"
      fullWidth
    >
      <Formik
        initialValues={CONFIRM_INVOICE_RECOMMENDATIONS_INITIAL_VALUES}
        validationSchema={CONFIRM_INVOICE_RECOMMENDATIONS_VALIDATION_SCHEMA}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          confirmInvoiceRecommendations({
            variables: { ...values },
            onCompleted: () => {
              setSubmitting(false);
              selectedInvoiceRecommendationIdsVar([]);
              resetForm();
              handleClose();
            },
          });
        }}
      >
        {({ values, isSubmitting, isValid, setFieldValue }) => (
          <Form>
            <Stack alignItems="center" spacing={3}>
              <Typography variant="h5">
                ¿En qué fecha enviará a evaluar el cliente?
              </Typography>
              <FingoDatePicker
                onChange={(value) => setFieldValue('date', moment(value))}
                value={values.date}
              />
              <LoadingButton
                id="decline-invoice-recommendations-button"
                variant="contained"
                color="primary"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting || !isValid}
                sx={{
                  width: 200,
                  fontSize: 16,
                  height: 40,
                }}
              >
                Confirmar
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </FingoDialog>
  );
};

ConfirmInvoiceRecommendationsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ConfirmInvoiceRecommendationsDialog;
