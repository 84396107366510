import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import MailFilter from '@fingo/lib/components/filters/MailFilter';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import { MailType } from '@fingo/lib/propTypes';

const Mail = (
  { selectedMail, setSelectedMail, openCreateMailForm, setOpenCreateMailForm, specificMode },
) => {
  useEffect(() => {
    if (!selectedMail) {
      setOpenCreateMailForm(false);
    }
  }, [selectedMail]);

  return (
    <>
      <Stack direction="row">
        <MailFilter
          size="small"
          sx={{ width: '300px' }}
          selectedMail={selectedMail}
          setSelectedMail={setSelectedMail}
          specificMode={specificMode}
        />
        {!openCreateMailForm && !selectedMail && (
          <IconButton
            id="create-mail"
            variant="contained"
            color="primary"
            onClick={() => setOpenCreateMailForm(true)}
          >
            <Add fontSize="12px" />
          </IconButton>
        )}
        {openCreateMailForm && (
          <IconButton
            id="close-mail"
            variant="contained"
            color="gray"
            onClick={() => setOpenCreateMailForm(false)}
          >
            <Close fontSize="12px" />
          </IconButton>
        )}
        {selectedMail && !openCreateMailForm && !specificMode && (
          <IconButton
            id="edit-mail"
            variant="contained"
            color="primary"
            onClick={() => setOpenCreateMailForm(true)}
          >
            <Edit fontSize="12px" />
          </IconButton>
        )}
      </Stack>
    </>
  );
};

Mail.propTypes = {
  selectedMail: MailType,
  setSelectedMail: PropTypes.func.isRequired,
  openCreateMailForm: PropTypes.bool.isRequired,
  setOpenCreateMailForm: PropTypes.func.isRequired,
  specificMode: PropTypes.bool.isRequired,
};

Mail.defaultProps = {
  selectedMail: null,
};

export default Mail;
