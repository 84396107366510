import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const ContractStatus = ({ value }) => {
  const contractStatus = {
    CREATED: 'Procesando',
    PENDINGGENERATION: 'Listo para generarse',
    GENERATED: 'Listo para firma' };
  return (
    <Typography>
      {contractStatus[value]}
    </Typography>
  );
};

ContractStatus.propTypes = {
  value: PropTypes.string.isRequired,
};

export default ContractStatus;
